import * as React from "react";
import PropTypes from "prop-types";

import { Helmet } from "react-helmet";
import Footer from "./footer";
import Logo from "../images/DTC2_Logo.png";

const Layout = ({ children }) => {
  const metaData = {
    title: "Da Stream Charades",

    description:
      'Play Charades On Stream! Using your Twitch account. Formerly called "Da Twitch Charades"', // eslint-disable-line
    url: "https://dastreamcharades.net",
    imageURL:
      "https://res.cloudinary.com/dyn8wmfzr/image/upload/v1654372681/DTC2_og_Image_2_tklwc7.jpg",
    keywords:
      "Twitch, Charades, Chat, Web App, Game, Live Stream, Da, Custom, Words On Stream, Guessing, Charadas",
    lang: "en",
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: metaData.lang }}>
        <meta charSet="utf-8" />
        <title translate="no">{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="image" content={metaData.imageURL} />
        <meta
          name="theme-color"
          media="(prefers-color-scheme: dark)"
          content="black"
        />

        <meta property="og:title" content={metaData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:image" content={metaData.imageURL} />
        <meta property="og:description" content={metaData.description} />
        <link href={Logo} rel="shortcut icon" type="image/x-icon" />
      </Helmet>
      <div style={{ minHeight: "100vh" }} className="relative">
        <div style={{ paddingBottom: "5rem" }}>{children}</div>

        <div
          style={{ height: "5rem" }}
          className="absolute bottom-0 w-full z-30"
        >
          <Footer />
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
